function QuickBasket() {

    var self = this;

    this.$forms = document.querySelectorAll('.js-basket-form');
    this.$quickBasket = document.querySelector('.js-quick-basket');
    this.$quickBasketWrap = document.querySelector('.js-quick-basket__wrap');
    this.$count = document.querySelector('.js-basket-count .header-action__count');

    this.clearDuration = 300;
    this.clearDelay = 200;
    this.timeout = 6000;
    this.clear;


    this.init = function() {
        if (!this.$forms || this.$forms.length == 0) {
            return;
        }

        Soho.plugins.delegate(document, 'submit', '.js-basket-form', (e) => this.handleQuickAdd(e));

        this.$quickBasketWrap.addEventListener('wishlist.add-all', event => {
            self.update(event.detail.basketCount)

            self.$quickBasketWrap.innerHTML = event.detail.popupHtml;

            self.$container = document.querySelector('.js-quick-basket__container');
            self.$close = document.querySelector('.js-quick-basket__close');
            // Assign click events to new elements
            self.$close.onclick = self.deactivate.bind(self);
            self.$quickBasket.onclick = self.deactivate.bind(self);

            self.$container.onclick = function(e) {
                e.stopPropagation();
            };
        })
    }

    this.handleQuickAdd = function(e) {
        e.preventDefault();

        const fromWishlist = window.location.pathname.endsWith('/wishlist')
        let form = e.target;

        self.clearErrors(form);
        self.add(
            form.getAttribute('action'),
            form.getAttribute('method'),
            new URLSearchParams(new FormData(form)).toString(),
            form,
            fromWishlist
        );
    }

    this.add = function(url, type, data, form, fromWishlist) {
        $.ajax({
            url: url,
            type: type,
            data: data,
            success: function(data) {
                if (data.result) {
                    // Update the quick basket and the basket count
                    self.update(data.payload.count);
                    // Add the html to the quick basket
                    self.$quickBasketWrap.innerHTML = data.payload.html;
                    // Define the new quick basket container and close elements
                    self.$container = document.querySelector('.js-quick-basket__container');
                    self.$close = document.querySelector('.js-quick-basket__close');
                    // Assign click events to new elements
                    self.$close.onclick = self.deactivate.bind(self);
                    self.$quickBasket.onclick = self.deactivate.bind(self);
                    self.$container.onclick = function(e) {
                        e.stopPropagation();
                    };
                    // Google Tag Manager
                    if (window.dataLayer) {
                        window.dataLayer.push({
                            'event': 'addToCart',
                            'ecommerce': {
                                'currencyCode': data.payload.trackingCurrency,
                                'add': {
                                    'products': [data.payload.item]
                                }
                            }
                        });

                        if (fromWishlist) {
                            window.dataLayer.push({
                                'event': 'd3rEvent',
                                'd3rEvent': {
                                    'category': 'Wishlist',
                                    'action': 'Add to Basket',
                                    'label': form.getAttribute('data-item-sku'),
                                },
                            })
                        }
                    }
                } else {
                    if (data.payload.errors) {
                        console.log(data.payload.errors);
                        self.addErrors(data.payload.errors, form);
                    }
                }
            }
        });
    }

    this.update = function(count) {
        this.activate();
        this.$count.innerHTML = count;
        this.clear = setTimeout(this.deactivate.bind(this), this.timeout);
    }

    this.activate = function() {
        this.$quickBasket.classList.add('quick-basket--activating');

        setTimeout(function() {
            self.$quickBasket.classList.add('quick-basket--active');
            self.$close.focus();
        }, 200);
    }

    this.deactivate = function() {
        this.$quickBasket.classList.remove('quick-basket--active');
        setTimeout(function() {
            self.$quickBasket.classList.remove('quick-basket--activating');
            clearTimeout(self.clear);
        }, this.clearDuration + this.clearDelay);
    }

    this.clearErrors = function(form) {
        var fields = form.querySelectorAll('.field');

        for (var index = 0; index < fields.length; index++) {
            var element = fields[index];
            element.classList.remove('field-error');
            var error = element.querySelector('p.error');
            if (error) {
                error.parentElement.removeChild(error);
            }
        }
    }

    this.addErrors = function(errors, form) {
        for (var name in errors) {
            if (errors.hasOwnProperty(name)) {
                var error = errors[name];
                this.addError(name, error, form);
            }
        }
    }

    this.addError = function(name, message, form)
    {
        var input = $('[name=' + name + ']', form);
        var field = input.parent('.field').get(0);

        var error = document.createElement('p');
        error.classList.add('error');
        error.innerHTML = message;

        field.classList.add('field-error');
        field.appendChild(error);
    }
}
